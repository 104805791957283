<template>
    <div style="background-color: #f7f7f7;padding-top: 30px;padding-bottom:30px;">
        <div v-if="queryDataA.type==1">
            <SleepChild :queryData='queryData'></SleepChild>
            <div style="height: 30px; background-color: #f7f7f7;"></div>
            <PressureChild :queryData='queryData'></PressureChild>
        </div>
        <div v-if="queryDataA.type==2">
            <PressureChild :queryData='queryData'></PressureChild>
            <div style="height: 30px; background-color: #f7f7f7;"></div>
            <SleepChild :queryData='queryData'></SleepChild>
        </div>
    </div>
</template>

<script>
import SleepChild from './components/sleepChild.vue'
import PressureChild from './components/pressureChild.vue'
export default {
  components: {
    SleepChild,
    PressureChild
  },
  data () {
    return {
      queryData: '',
      queryDataA: ''
    }
  },
  created () {
    this.queryDataA = this.$route.query
    this.queryData = JSON.stringify(this.$route.query)
    console.log('this.$route.query', this.$route.query)
    // console.log('this.queryData',this.queryData)
  }
}
</script>

<style lang="scss" scoped>

</style>
