<template>
    <div class="history-tabbar">
        <div @click="changeActiveIndex(index)" :class="['tab',currentIndex == index?'active':'' ]"
        v-for="(item, index) in historyTabList" :key="index"> {{ item.text }}</div>
    </div>
</template>

<script>
export default {
    name:'HistoryBar',
    data() {
        return {
            historyTabList:[{
                text:'近1周',
                value:1
            },{
                text:'近1月',
                value:2
            },{
                text:'近3月',
                value:3
            },{
                text:'近6月',
                value:4
            },{
                text:'近1年',
                value:5
            }],
            currentIndex:0
        };
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        changeActiveIndex(index){
            this.currentIndex = index
            this.$emit('onIndexCahnge',index)
        },
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
    .history-tabbar {
        width:100%;
        display: flex;
        justify-content: space-evenly;
        font-size: 15px;
        margin-bottom: 20px;

        .tab {
            // width: 55px;
            // padding: 8px;
            color: #868686;
            padding:5px 10px;
            &.active{
                border-radius: 20px;
                text-align: center;
                background: #ffe6e2;
                color: #ff9180;
                // padding: 10px 15px;
            }
        }
    }
</style>